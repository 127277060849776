
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customer_api } from '../../../API/services/customer.js';
import { getTokenFromSession } from '../../../helpers.js';

const initialState = {
  customers: [],
  loading: false,
  error: null,
};

// fetchCustomers
export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (_, { rejectWithValue }) => {
    try {
      const token= await getTokenFromSession();
      const response = await axios.get(`${customer_api.getallcustomer.url}`, {
        headers: {
            "Authorization": `Berear ${token}`
        }
    });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: 'customerInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default customerSlice.reducer;

