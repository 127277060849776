
import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customer_api } from '../../../API/services/customer.js';
import { getTokenFromSession } from '../../../helpers.js';

const initialState = {
  customerSettings: [],
  customerId: null,
  loading: false,
  error: null,
  loadingDelete: false,
  errorDelete: null,
  deleteMessage: null
};

// fetchCustomer Settings
export const fetchCustomerSettings = createAsyncThunk(
  'customers/fetchCustomerSettings',
  async (c_id, { rejectWithValue }) => {
    try {
      const token= await getTokenFromSession();
      const response = await axios.get(`${customer_api.getcustomersetting.url}`, {
        headers: {
          "Authorization": `Berear ${token}`
        },
        params: { c_id: c_id }
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCustomer = createAsyncThunk("customers/deleteCustomer", async (c_id, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.delete(`${customer_api.deletecustomer.url}?c_id=${c_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.message;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const customerSettingsSlice = createSlice({
  name: 'customerSettings',
  initialState,
  reducers: {
    updateSelectedCustomer(state, action) {
      state.customerId = action.payload.uuid;
      state.selectedAction = action.payload.action;
    },
    resetDeleteValues(state, action) {
      state.deleteMessage = null;
      state.errorDelete = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.customerSettings = action.payload;
      })
      .addCase(fetchCustomerSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deleteMessage = action.payload;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      });
  },
});
export default customerSettingsSlice.reducer;
export const { updateSelectedCustomer, resetDeleteValues } = customerSettingsSlice.actions

