import { combineReducers } from "redux";
import customersReducer from "./features/customers/customersSlice";
import profilePictureReducer from "./features/profilePicture/profilePictureSlice";
import themeReducer from "./features/theme/themeSlice";
import userReducer from "./features/user/userSlice";
import groupsReducer from "./features/groups/groupsSlice";
import templatesReducer from "./features/templates/templatesSlice";
import assetReducer from "./features/assets/assetSlice";
import usageReducer from "./features/usage/usageSlice";
import forgotPasswordReducer from "./features/login/forgotPasswordSlice";
import sessionStateReducer from "./features/session/sessionStateSlice";
import viewIncidentsReducer from "./features/incidents/getIncidentsSlice";
import parentCompaniesSliceReducer from "./features/companies/parentCompaniesSlice";
import customerSettingsSliceReducer from "./features/customers/getCustomerSlice";
import getCustomersettingsSlice from "./features/customers/getCustomersettingsSlice";
import getRolesSliceReducer from "./features/roles/getRoles";
import getAllUserSliceReducer from "./features/user/getAllUserSlice";
import getUserSlice from "./features/user/getUserSlice";
import getUserSettingsSlice from "./features/settings/getUserSettingsSlice";
import getKCSliceReducer from './features/kc/getKCSlice';
import getSelectedUserSettingsSlice from "./features/settings/getSelectedUserSettingsSlice";

const rootReducer = combineReducers({
  ThemeOptions: themeReducer,
  user: userReducer,
  profilePicture: profilePictureReducer,
  customers: customersReducer,
  groupsOptions: groupsReducer,
  templatesOptions: templatesReducer,
  assetOptions: assetReducer,
  usageOptions: usageReducer,
  forgotPassword: forgotPasswordReducer,
  sessionState: sessionStateReducer,
  IncidentsState: viewIncidentsReducer,
  company: parentCompaniesSliceReducer,
  getCustomers: customerSettingsSliceReducer,
  settings: getCustomersettingsSlice,
  app: getRolesSliceReducer,
  allUsers: getAllUserSliceReducer,
  getUser: getUserSlice,
  userSettings: getUserSettingsSlice,
  allKC : getKCSliceReducer, 
  selectedUserSettings: getSelectedUserSettingsSlice 
});

export default rootReducer;
