import storageSession from "redux-persist/lib/storage/session"; // Use sessionStorage
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage: storageSession, // Use sessionStorage
  version: 1,
  whitelist: ["user", "customers", "settings", "userSettings"], // Include only these state keys for persistence
  // other options like whitelist, blacklist, etc.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export { persistedReducer, persistStore };
