import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { settings_api } from "../../../API/services/settings";
import { getTokenFromSession } from "../../../helpers.js";

const initialState = {
  userSettings: null,
  loading: false,
  error: null,
};

// fetch users
export const fetchUserSettings = createAsyncThunk("settings/fetchUserSettings", async (userId, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.get(`${settings_api.getUserSettings.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { userId: userId },
    });
    if (response.data.success)
      return response.data.data;
    else
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getUserSettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetUserSettings(state, action) {
      state.userSettings = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.userSettings = action.payload;
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { resetUserSettings } = getUserSettingsSlice.actions;
export default getUserSettingsSlice.reducer;
