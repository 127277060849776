let backend_domain = window._env_.REACT_APP_API_URL
let api_version = window._env_.REACT_APP_API_VERSION

//admin api's
export const settings_api = {
    getUserSettings: {
        method: "GET",
        url: `${backend_domain}${api_version}/get-user-settings`,
        headers: { 'Content-Type': 'application/json' }
    },    
}