import { createSlice } from "@reduxjs/toolkit";
// import screenVideo from "./telepresenz.mp4";

const initialState = {
    isMaxView: true,
    maxViewUser: null,
    maxViewSrc: "https://mdbootstrap.com/img/video/animation-intro.mp4",
    role: "primary expert",
    isScreenCastMode: false,
    // screenCastObject: {id: 4, src: screenVideo},
    isSpeakerAllMuted: false,
    isMicAllMuted: false,
    showAnnTool: false,
    showCamTool: false,
    isFileShare: false,
    isCollapseTool: false,
    showColorPick: false,
    showGuestForm: false,
    isReqControl: false,
    isChatOpen: false,
    isCollapseSidePanel: false,
    isRecording: false,
    pageChange: "",

    participants: [
        {id: 1, src:"https://mdbootstrap.com/img/video/animation-intro.mp4"},
        {id: 2, src: "https://mdbcdn.b-cdn.net/img/video/forest.mp4"},
        {id: 3, src: "https://mdbcdn.b-cdn.net/img/video/Agua-natural.mp4"},        
        {id: 4, src: "https://mdbcdn.b-cdn.net/img/video/Tropical.mp4"},
        {id: 5, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"},
        {id: 6, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"},
        {id: 7, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4"},
        {id: 8, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"},
        {id: 9, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4"},
        {id: 10, src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"},
    ],

};

const sessionStateSlice = createSlice({
    name: "session_state",
    initialState,
    reducers: {
        toggleMaxView(state, action) {
            return { ...state, ...action.payload };
        },
        setMaxviewUser(state, action) {
            return { ...state, ...action.payload };
        },
        toggleSpeakerAll(state, action) {
            return { ...state, ...action.payload };
        },
        toggleMicAll(state, action) {
            return { ...state, ...action.payload };
        },
        toggleAnnotateToolbar(state, action) {
            return { ...state, ...action.payload };
        },
        toggleShapepicker(state, action) {
            return { ...state, ...action.payload };
        },
        toggleColorpicker(state, action) {
            return {...state, ...action.payload };
        },
        toggleCameraToolbar(state, action) {
            return { ...state, ...action.payload };
        },
        toggleFileShare(state, action) {
            return { ...state, ...action.payload };
        },
        toggleChat(state, action) {
            return { ...state, ...action.payload };
        },
        toggleAddGuestModal(state, action) {
            return { ...state, ...action.payload };
        },
        toggleHand(state, action) {
            return { ...state, ...action.payload };
        },
        toggleScreenShare(state, action) {
            return { ...state, ...action.payload };
        },
        updateParticipant(state, action) {
            state.participants.push(action.payload)
        },
        toggleSidePanel(state, action) {
            return { ...state, ...action.payload };
        },
        toggleRecording(state, action) {
            return { ...state, ...action.payload };
        },
        pageNavigation(state, action) {
            return { ...state, ...action.payload };
        }
    }
});

export default sessionStateSlice.reducer;
export const { 
    toggleMaxView,
    setMaxviewUser,
    toggleSpeakerAll,
    toggleMicAll,
    toggleAnnotateToolbar,
    toggleShapepicker,
    toggleColorpicker,
    toggleCameraToolbar,
    toggleFileShare,
    toggleChat,
    toggleAddGuestModal,
    toggleHand,
    toggleScreenShare,
    updateParticipant,
    toggleSidePanel,
    toggleRecording,
    pageNavigation
} = sessionStateSlice.actions;