import AuthService from '../../../AuthService.js';

import {
    createSlice,   
    createAsyncThunk 
} from '@reduxjs/toolkit';

let authService = new AuthService();
const initialState = {
    assets: [], 
    isLoading: false,
    errorMessage: undefined,
    selectedAsset: undefined,
    selectedAction: ''
}

export const fetchIndividualAsset = createAsyncThunk(
  'assets/getAssetDetails',
  async ({assetValue, accountValue}) => {
    let url = "jobs/getAssetDetails";
    const response = await authService.fetch(url, 
      {
        method: 'post',
        body: JSON.stringify({id: assetValue, account_id: accountValue})
      }
    )
    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return response.status
    }
    else if (data && data !== null)
      return data;
    else
      return "The fetchIndividualAsset API didn't return a response";    
  }  
)
const assetSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        /**This is a reducer for storing the templatedata from the dummy JSON 
         * To be removed after API integration
        */
        addAssetsFromJson(state, action) {
          state.assets = action.payload;
        },        
        updateSelectedAsset(state, action){
          state.selectedAsset = action.payload.id;
          state.selectedAction = action.payload.action;
        }        
    },
    extraReducers: (builder) =>{
      builder.addCase(fetchIndividualAsset.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchIndividualAsset.fulfilled, (state, action) => {
        state.assetDetails = action.payload;
        state.isLoading = false;
      }),
      builder.addCase(fetchIndividualAsset.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isLoading = false;
      })
    }
})



export default assetSlice.reducer;
export const { addAssetsFromJson, updateSelectedAsset} = assetSlice.actions