import React, { useState, useEffect, createContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import socketIOClient from 'socket.io-client'

import { printConsole } from '../helpers.js';
import AuthService from '../AuthService.js';
import { setOnlineUsers } from "../store/features/user/userSlice";

const SocketContext = createContext();

const SocketClient = (props) => {

    const dispatch = useDispatch();
    const [socket, setSocket] = useState(null);
    const authService = new AuthService();
    const onlineUsers = useSelector(state => state.user.onlineUsers);

    //when page refreshes or component reloads post login
    useEffect(() => {
        socketInit();
        return () => {
            disconnect();
        }
    },[]);
    
    //Listener to update online users
    useEffect(() => {
        printConsole(`socket obj is ${socket}`);
        if(socket){
            socket.on("connect_error", onConnectionError);
            socket.on('connect', onConnect);
            socket.on('disconnect', onDisconnect);
            socket.on("isOnline", onIsOnline);
            socket.on('isOffline', onIsOffline);
        
            printConsole(`connection state of socket is ${socket.connected}`);
            connect();

            return () => {
                socket.off("connect_error");
                socket.off("connect");
                socket.off("disconnect");
                socket.off("isOnline");
                socket.off("isOffline");
            }
        }
	},[socket]);

    //initialise the socket
    const socketInit = () => {
        const token = authService.getToken() // Getting token from localstorage
        if (!socket && token) {
            const socketObj = socketIOClient(window._env_.REACT_APP_API_SOCKET_URL || 'localhost:5001', {
                autoConnect: false,//We will connect later on otherwise cannot catch the isOnline signals. Its coming before registering the listener
                query: { token: token },
                transports: ['websocket'],
                /** For local environmet use default path else use nginx reverseproxy */
                path: window._env_.REACT_APP_ENV === 'local' ? '/socket.io' : '/ws/socket.io'
            });
            setSocket(socketObj)
        }
    }

    //disconnect the socket
    const disconnect = () => {
        if (socket) {
            socket.disconnect();
            setSocket(null)
        }
    }

    //explicitly connect the user socket
    const connect = () => {
        if (socket && !socket.connected) {
            printConsole('connecting the socket!!!!!!!')
            socket.connect();
        }
    }

    const  onConnect = () => {
        printConsole(`socket connected ======`);
    }
  
    const onDisconnect = () => {
        printConsole(`======= socket disconnected`);
    }
  
    const onConnectionError = (err) => {
        printConsole(`connect_error due to ${err}`);
    }

    const onIsOnline = (data) =>  {
        printConsole(`online users are ${data}`)
        dispatch(setOnlineUsers({online_users: data}))
    }

    const onIsOffline = (data) =>  {
        printConsole(`offline users are ${data}`)
        const updated_online_users = onlineUsers.filter(email => email !== data)
        dispatch(setOnlineUsers({online_users: updated_online_users}))
    }

    return (
        <SocketContext.Provider value={{socket, socketInit, connectSocket: connect, disconnectSocket: disconnect}}>
            {props.children}
        </SocketContext.Provider>
    );
    
}

export { SocketContext, SocketClient }
    