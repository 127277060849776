import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, CardBody } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { register_api } from "../../../API/services/registration";
import axios from "axios";

export default function ReGenerateLink(props) {
  const { user_id } = useParams();
  const history = useHistory();

  const [isLinkActive, setLinkActive] = useState(false);

  //check link status
  const checkLinkStatus = async () => {
    try {
      const response = await axios.get(register_api.check_link_status.url, {
        params: { userId: user_id, category: "forgot-password" },
      });
      if (response.data.success) {
        setLinkActive(response.data.linkActive);
      } else {
        //toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    checkLinkStatus();
  }, [isLinkActive]);

  return (
    <div className="h-100">
      <Row className="h-100 g-0">
        <Col lg="12" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center" >
        <Col lg="12" md="12" className="mx-auto mx-auto app-login-box">
          
              <div className="login-logo mb-4" />
                <div className="container ">
                  <Row className="divider" />   
                  <h4 className="mb-5 mt-5 d-flex justify-content-center">
                    <span>The&nbsp;<strong>{props.type}</strong>&nbsp;link is no longer valid.</span>&nbsp;
                    <span>Please click the button below to re-direct to login page.</span>
                  </h4>
                  <Row className="divider" />                  
                  <div className="col-auto- d-flex justify-content-end">
                    <Button
                      color="primary"
                      size="lg"
                      className="mb-2 btn-wide btn-pill btn-shadow btn-hover-shine"
                      onClick={() => history.push("/")}
                    >
                      Go Back
                    </Button>
                  </div>
               </div>
            
        </Col>
        </Col>
      </Row>
    </div>
  );
}
