import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loaders";
import Slider from "react-slick";
import bg1 from "../../../assets/utils/images/originals/4.jpg";
import bg2 from "../../../assets/utils/images/originals/5.jpg";
import bg3 from "../../../assets/utils/images/originals/6.jpg";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Card,
  CardBody,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { register_api } from "../../../API/services/registration";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { AES_256_GCM_Encrypt, capitalizeFirstLetter } from "../../../helpers";
import ReGenerateLink from "../common/ReGenerateLink";

const slider_settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

export default function ForgotPassword() {
  const { user_id } = useParams();
  const history = useHistory();
  const [errMsg, setErrorMsg] = useState("");
  //console.log("user_id", user_id)

  const [formData, setFormData] = useState({
    forgot_password: true,
    user_id: user_id,
    password: "",
    confirm_password: "",
  });

  const [isLoading, setLoader] = useState(false);

  //Form validation code
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  console.log(errors);

  const patternMatch = (string, regex) => {
    if (regex.test(string) === false) return true;
    else return false;
  };

  const [alphaSmallCaseValidationError, setAlphaSmallCaseValidationError] = useState(true);
  const [alphaLargeCaseValidationError, setAlphaLargeCaseValidationError] = useState(true);
  const [numberValidationError, setNumberValidationError] = useState(true);
  const [spCharValidationError, setSpCharValidationError] = useState(true);
  const [charLengthValidationError, setCharLengthValidationError] = useState(true);
  const [passwordMissmatchError, setPasswordMissmatchError] = useState(false);
  const [isLinkActive, setLinkActive] = useState(false);

  //input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    if (name === "password") {
      setAlphaSmallCaseValidationError(patternMatch(value, /[a-z]/g));
      setAlphaLargeCaseValidationError(patternMatch(value, /[A-Z]/g));
      setNumberValidationError(patternMatch(value, /^(?=.*\d)/gi));
      setSpCharValidationError(patternMatch(value, /^(?=.*[@#$!*&])/gi));
      setCharLengthValidationError(patternMatch(value, /^[A-Za-z\d@#$!*&]{8,15}/gi));
    }
  };

  //form submit
  const onSubmit = async (data) => {
    try {
      //console.log("submit button clicked",data, Object.keys(errors).length);
      if (Object.keys(errors).length === 0) {
        if (formData.password != formData.confirm_password) {
          //setErrorMsg('password and confirm password must same')
          setPasswordMissmatchError(true);
          return;
        } else {
          setPasswordMissmatchError(false);
        }
        setLoader(true);
        let encryptedData = await AES_256_GCM_Encrypt(formData);
        const response = await axios.post(register_api.register.url, encryptedData);
        if (response.data.success) {
          setLoader(false);
          toast.success(capitalizeFirstLetter(response.data.message));
          setFormData({
            password: "",
            confirm_password: "",
          });
          history.push("/");
        } else {
          setLoader(false);
          toast.error(capitalizeFirstLetter(response.data.message));
        }
      }
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
    }
  };

  //check link status
  const checkLinkStatus = async () => {
    try {
      const response = await axios.get(register_api.check_link_status.url, {
        params: { userId: user_id, category: "forgot-password" },
      });
      if (response.data.success) {
        setLinkActive(response.data.linkActive);
      } else {
        //toast.error(capitalizeFirstLetter(response.data.message));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    checkLinkStatus();
  }, [isLinkActive]);

  const { ref, ...password } = register("password", {
    required: "Password field is required",
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!*&])[A-Za-z\d@#$!*&]{8,15}$/i,
  });

  return (
    <div className="h-100">
      {isLinkActive ? (
        <>
          <Row className="h-100 g-0">
            {/* form */}
            <Col
              lg="8"
              md="12"
              className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center"
            >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="login-logo" /> <br />
                <h4>
                  <div>Hello,</div>
                  <span className="text-success">Create a New password</span>
                </h4>
                <Row className="divider" />
                <div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup>
                          <Label for="password">
                            {" "}
                            <span className="text-danger">*</span> Password{" "}
                          </Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            tabIndex="1"
                            innerRef={ref}
                            {...password}
                            placeholder="Enter new password"
                            invalid={errors.password ? true : false}
                            value={formData.password}
                            onChange={handleInputChange}
                          />

                          {errors.password && errors.password.type === "pattern" && (
                            <span className="error-msg">Check the password format</span>
                          )}
                          {errors.password && errors.password.type === "required" && (
                            <span className="error-msg">{errors.password.message}</span>
                          )}

                          <UncontrolledPopover placement="top" target="password" trigger="focus">
                            <PopoverHeader cssModule={{ width: "500px" }}>Password Hint</PopoverHeader>
                            <PopoverBody cssModule={{ width: "500px" }}>
                              <ul className="fa-ul">
                                <li>
                                  <span className="fa-li">
                                    <FontAwesomeIcon
                                      icon={alphaLargeCaseValidationError ? faCircleXmark : faCheckCircle}
                                      className={
                                        "me-1" +
                                        (alphaLargeCaseValidationError ? " hint-msg-failed" : " hint-msg-passed")
                                      }
                                    />
                                  </span>
                                  One uppercase character
                                </li>
                                <li>
                                  <span className="fa-li">
                                    <FontAwesomeIcon
                                      icon={alphaSmallCaseValidationError ? faCircleXmark : faCheckCircle}
                                      className={
                                        "me-1" +
                                        (alphaSmallCaseValidationError ? " hint-msg-failed" : " hint-msg-passed")
                                      }
                                    />
                                  </span>
                                  One lowercase character
                                </li>
                                <li>
                                  <span className="fa-li">
                                    <FontAwesomeIcon
                                      icon={numberValidationError ? faCircleXmark : faCheckCircle}
                                      className={
                                        "me-1" + (numberValidationError ? " hint-msg-failed" : " hint-msg-passed")
                                      }
                                    />
                                  </span>
                                  One number
                                </li>
                                <li>
                                  <span className="fa-li">
                                    <FontAwesomeIcon
                                      icon={spCharValidationError ? faCircleXmark : faCheckCircle}
                                      className={
                                        "me-1" + (spCharValidationError ? " hint-msg-failed" : " hint-msg-passed")
                                      }
                                    />
                                  </span>
                                  One special character (Eg, !, @, #, $, &, *)
                                </li>
                                <li>
                                  <span className="fa-li">
                                    <FontAwesomeIcon
                                      icon={charLengthValidationError ? faCircleXmark : faCheckCircle}
                                      className={
                                        "me-1" + (charLengthValidationError ? " hint-msg-failed" : " hint-msg-passed")
                                      }
                                    />
                                  </span>
                                  8 - 15 characters
                                </li>
                              </ul>
                            </PopoverBody>
                          </UncontrolledPopover>
                          {/* <FormFeedback>{errors.password}</FormFeedback> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <FormGroup>
                          <Label for="confirm_password">
                            {" "}
                            <span className="text-danger">*</span> Confirm Password{" "}
                          </Label>
                          <Input
                            type="password"
                            required
                            name="confirm_password"
                            id="confirm_password"
                            tabIndex="2"
                            /* innerRef={refcon} {...confirm_password} */
                            placeholder="Confirm the same password"
                            invalid={/* errors.confirm_password ||  */ passwordMissmatchError ? true : false}
                            value={formData.confirm_password}
                            onChange={handleInputChange}
                          />

                          {/* {watch("confirm_password") !== watch("password") &&
                              getValues("confirm_password") ? (
                                <span className="error-msg">Confirm password should be exactly same as Password field</span>
                              ) : null} */}

                          {passwordMissmatchError === true && (
                            <span className="error-msg">Confirm password should be exactly same as Password field</span>
                          )}
                          {/* errors.confirm_password && errors.confirm_password.type === "required" && (
                          <span className="error-msg">{errors.confirm_password.message}</span>
                        )*/}
                        </FormGroup>
                      </Col>
                    </Row>
                    {errMsg && (
                      <div>
                        <p style={{ height: "20px", color: "red" }}> {errMsg} </p>
                      </div>
                    )}
                    <Row className="divider" />
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0">
                        To go back{" "}
                        <a onClick={() => history.push("/")} style={{ cursor: "pointer" }} className="text-primary">
                          Login
                        </a>
                      </h5>

                      <div className="ms-auto">
                        { isLoading === true ?
                          <Button
                            type="text"
                            color="primary"
                            tabIndex="3"
                            className="btn-wide btn-pill btn-shadow btn-hover-shine"
                            size="lg" /* onClick={handleSubmit} */
                          >
                            <Loader color="#fff" type="ball-pulse"/>
                          </Button>
                          :
                          <Button
                            type="submit"
                            color="primary"
                            tabIndex="3"
                            className="btn-wide btn-pill btn-shadow btn-hover-shine"
                            size="lg" /* onClick={handleSubmit} */
                          >
                            Update
                          </Button>
                        }
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Col>

            {/* image */}
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...slider_settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>SMART OPS</h3>
                      <p>Remote Expert Guidance | Live Digital Inspections | Incident Management</p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>SMART ITM</h3>
                      <p>Knowledge Capture | DocXplore | Training Management</p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg3 + ")",
                      }}
                    />
                    <div className="slider-content">
                      <h3>SMART EYE</h3>
                      <p>Remote Monitor | Live Feeds | Workflows | Smart Dashboards</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <ReGenerateLink type={"reset password"}/>
      )}
      <ToastContainer />
    </div>
  );
}
