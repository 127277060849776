import AuthService from '../../../AuthService.js';

import {
    createSlice,   
    createAsyncThunk 
} from '@reduxjs/toolkit';

let authService = new AuthService();
const initialState = {
    usageDetails: [], 
    isLoading: false,
    startdownload: false,
    errorMessage: undefined    
}

export const fetchUsageDetails = createAsyncThunk(
    'usage/fetchUsageDetails',
    async ({userValue, isAdmin, perPage}) => {
      let url = "sessionDetails/";
      url += userValue; //by user id
      url += `/${isAdmin}`;
      url += `?perpage=${perPage}&currentpage=1`;
      const response = await authService.fetch(url, { method: 'GET' })
      const data = await response.json();
      if (response.status < 200 || response.status >= 300) {
        return response.status
      }
      else if (data && data !== null)
        return data;
      else
        return "The fetchUsageDetails API didn't return a response";    
    }  
)

const usageSlice = createSlice({
    name: "usage",
    initialState,    
    reducers: {
        triggerDownload(state, action) {
            state.startdownload = action.payload;
        }
    },
    extraReducers: (builder) =>{
      builder.addCase(fetchUsageDetails.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchUsageDetails.fulfilled, (state, action) => {
        state.usageDetails = action.payload.records;
        state.isLoading = false;
      }),
      builder.addCase(fetchUsageDetails.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isLoading = false;
      })
    }
})



export default usageSlice.reducer;
export const { triggerDownload } = usageSlice.actions