import AuthService from '../../../AuthService.js';

import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

let authService = new AuthService();

const initialState = {
  users: [],
  userArr: [],
  userPictures: [],
  isFetching: false,
  errorMessage: undefined,
  isLoading:false,
  selectedUser: undefined,
  selectedAction: '', 
  isEditSubmitted: false,
  isEditUser: false
};

export const fetchUsers = createAsyncThunk(
  'profilePicture/fetchUsers',
  async accountValue => {
    if(accountValue !== undefined){
      let url = "getusersbyaccountid/"+accountValue;
      const response = await authService.fetch(url,{ method: 'GET'})
      const data = await response.json();
      if (response.status < 200 || response.status >= 300) {
        return response.status
      }      
      return data.users;      
    }
  },
);

export const fetchPictures = createAsyncThunk (
  'profilePicture/fetchPictures',
  async userArrayValue => {  
    if(userArrayValue !== undefined){
      let url = "user/getProfilePicture/";
      const params = {
          user_ids: userArrayValue
      }
      const response = await authService.fetch(url, {method: 'POST', body: JSON.stringify(params)})
      const data = await response.json();
      if (response.status < 200 || response.status >= 300) {
        return response.status
      }
      return data;       
    }
  }
);

const profilePictureSlice = createSlice({
  name: "profilePicture",
  initialState,
  reducers: {
    updateSelectedUser(state, action) {
      state.selectedUser = action.payload.id;
      state.selectedAction = action.payload.action;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {      
        state.isFetching =  true
    }),
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isFetching = false        
    }),   
    builder.addCase(fetchUsers.rejected, (state, action) => {
        state.isFetching = false;
        state.errorMessage = action.payload;
    }),
    builder.addCase(fetchPictures.pending, (state) => {
        state.isLoading = true;
    }), 
    builder.addCase(fetchPictures.fulfilled, (state, action) => {    
        state.users = state.users.map((u) => {
            let [user] = action.payload.filter(usr => usr.user_id === u.id);
            let newUser = Object.assign({},u);
            if (user !== undefined) {
              newUser.profile_picture = user.my_picture;              
            } else {
              newUser.profile_picture = null;
            }
            return newUser;
        });
        state.userPictures = action.payload;
        state.isLoading = false;
        
    }),
    builder.addCase(fetchPictures.rejected, (state, action) => { 
        state.isFetching = false;
        state.errorMessage = action.payload;
    })    
  }
});

export default profilePictureSlice.reducer; 
export const { updateSelectedUser } = profilePictureSlice.actions


