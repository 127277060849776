let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

//customer api's
export const register_api = {
  register: {
    method: "POST",
    url: `${backend_domain}${api_version}/register`,
    headers: { "Content-Type": "application/json" },
  },
  sign_in: {
    method: "POST",
    url: `${backend_domain}${api_version}/sign-in`,
    headers: { "Content-Type": "application/json" },
  },
  reset_pwd_link: {
    method: "POST",
    url: `${backend_domain}${api_version}/password-reset-link`,
    headers: { "Content-Type": "application/json" },
  },
  sign_out: {
    method: "POST",
    url: `${backend_domain}${api_version}/sign-out`,
    headers: { "Content-Type": "application/json" },
  },
  check_link_status: {
    method: "GET",
    url: `${backend_domain}${api_version}/check-link-status`,
    headers: { "Content-Type": "application/json" },
  },
};
