import AuthService from '../../../AuthService.js';
import { getProperRoles } from '../../../helpers';

import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
  
let authService = new AuthService();

const defaultRoles = {
  "admin": "Admin", 
  "expert": "Expert", 
  "technician": "Technician"
}

const initialState = {
  purchases: [],
  customer: [],  
  creditsAvailable: 0,
  isFetching: false,
  errorMessage: undefined
};

export const fetchMyAccountDetails = createAsyncThunk (
    'customers/fetchMyAccountDetails',
    async accountValue => {    
      if(accountValue !== undefined){
        let url = "getAccoutDetails/"+accountValue;
        const response = await authService.fetch(url, { method: 'GET' })
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }
        return data; 
        /* const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', "Bearer " + sessionStorage.getItem("id_token"));
        fetch(sessionStorage.getItem("url") + "/api/getAccoutDetails/"+accountValue,{ method: 'GET', headers: myHeaders})
        .then((response) => response.json())
        .then((data) => {
            dispatch(fetchCustomerDetailsSuccess(data)); 
        })
        .catch((error) => {
          dispatch(fetchCustomerDetailsFailure(error))
        }); */
      }
    }
);

const customersSlice = createSlice({
    name: "customers",
    initialState,
    extraReducers: (builder) => {         
        builder.addCase(fetchMyAccountDetails.pending, (state) => {
            state.isFetching = true
        }),
        builder.addCase(fetchMyAccountDetails.fulfilled, (state, action) => {
            let rolesArr = {};
            if (action.payload.customer.generic_name === undefined || action.payload.customer.generic_name === null) rolesArr = defaultRoles;
            else rolesArr = getProperRoles(action.payload.customer.generic_name, defaultRoles)        
            //const newCustomerDetails = [ ...action.payload.customer, {role: rolesArr} ]
            let newCustomerDetails = Object.assign(action.payload.customer, {role: rolesArr});
            //newCustomerDetails.role = rolesArr;
            state.purchases = action.payload.purchases;
            state.customer = newCustomerDetails;
            state.creditsAvailable = action.payload.purchases.reduce(function(prev, cur) {
                return prev + cur.available_credits;
            }, 0);
            state.isFetching = false
        
        }),
        builder.addCase(fetchMyAccountDetails.rejected, (state, action) => {
            state.isFetching = false;
            state.errorMessage = action.payload
        });     
    
    },
});

export default customersSlice.reducer;