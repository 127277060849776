// getKCSlice.js
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { kc_api } from "../../../API/services/knowledgecapture";

const initialState = {
  kc: [],
  selectedAction: "",
  selectedKC: null,
  selectedEditKC: null,
  loading: false,
  error: null,
  loadingEdit: false,
  errorEdit: null,
  message: null,
};

export const fetchAllKC = createAsyncThunk("view/fetchAllKC", async (_, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.get(`${kc_api.getAllKC.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateKCAPI = createAsyncThunk("update/kc", async (payload, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.patch(`${kc_api.updateKC.url}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getKCSlice = createSlice({
  name: "allKC",
  initialState,
  reducers: {
    selectKC(state, action) {
      state.selectedKC = action.payload.id;
      console.log("Action Payload:", action.payload);
    },
    getEditKCParams(state, action) {
      const [selectedEditKC] = state.kc.filter((kc) => kc.kc_id === state.selectedKC);
      state.selectedEditKC = selectedEditKC ;      
    },
    updateKC(state, action) {
      //console.log("updateKC_action", action);
      const { key, value } = action.payload;
      state.selectedEditKC[key] = value;
      // console.log(state.selectedEditKC);
    },
    updateEditParams  (state, action) {
      state.loadingEdit = false;
      state.errorEdit = null;
      state.message = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllKC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllKC.fulfilled, (state, action) => {
        state.loading = false;
        state.kc = action.payload;
      })
      .addCase(fetchAllKC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateKCAPI.pending, (state) => {
        state.loadingEdit = true;
        state.errorEdit = null;
      })
      .addCase(updateKCAPI.fulfilled, (state, action) => {
        state.loadingEdit = false;
        state.message = action.payload.message;
      })
      .addCase(updateKCAPI.rejected, (state, action) => {
        state.loadingEdit = false;
        state.errorEdit = action.payload.message;
      });
  },
});

// Actions
export const { selectKC, getEditKCParams, updateKC, updateKCTasks, updateEditParams } = getKCSlice.actions;
export default getKCSlice.reducer;
