import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customer_api } from "../../../API/services/customer.js";
import { admin_api } from "../../../API/services/admin.js";
import { getTokenFromSession } from "../../../helpers.js";

const initialState = {
  userDetail: [],
  loading: false,
  error: null,
};

// fetch users
export const fetchUser = createAsyncThunk("user/fetchUser", async ({ c_id, uuid }, { rejectWithValue }) => {
  try {    
    const token = await getTokenFromSession();
    const response = await axios.get(`${admin_api.getUser.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { c_id: c_id, user_id: uuid },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getUserSlice = createSlice({
  name: "getUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetail = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default getUserSlice.reducer;
