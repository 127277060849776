import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customer_api } from "../../../API/services/customer.js";
import { admin_api } from "../../../API/services/admin.js";
import { getTokenFromSession } from "../../../helpers.js";

const initialState = {
  users: [],
  loading: false,
  error: null,
  loadingDelete: false,
  deleteMsg: null,
  errorDelete: null
};

// fetch users
export const fetchAllUsers = createAsyncThunk("user/fetchAllUsers", async (role, { rejectWithValue }) => {
  try {
    //console.log("role__",role)
    const token = await getTokenFromSession();
    const response = await axios.get(`${admin_api.getAllUsers.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        role: role,
      },
    });
    if (response.data.success)
      return response.data.data;
    else return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async (user_id, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.delete(`${admin_api.deleteUser.url}?user_id=${user_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.message;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const getAllUserSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    updateDelParams  (state, action) {
      state.loadingDelete = false;
      state.errorDelete = null;
      state.deleteMsg = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loadingDelete = true;
        state.errorDelete = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.deleteMsg = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loadingDelete = false;
        state.errorDelete = action.payload;
      });
      
  },
});
export const { updateDelParams } = getAllUserSlice.actions;
export default getAllUserSlice.reducer;
