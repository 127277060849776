import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { admin_api } from "../../../API/services/admin";
import { getTokenFromSession } from "../../../helpers";

// Define the initial state
const initialState = {
  roles: [],
  loading: false,
  error: null,
};

// Define the async thunk for fetching roles
export const fetchAllRoles = createAsyncThunk("roles/fetchAllRoles", async (type, { rejectWithValue }) => {
  const token = await getTokenFromSession();
  try {
    const response = await axios.get(admin_api.getRoles.url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { type: type },
    });
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Create the slice
const getRolesSlice = createSlice({
  name: "system_roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(fetchAllRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer to be used in the store
export default getRolesSlice.reducer;
