import AuthService from '../../../AuthService.js';

import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

let authService = new AuthService();
const initialState = {
  groups: [],  
  groupusers: [],
  isGroupLoading: false,
  errorMessage: undefined,
  successMessage: undefined,
  selectedGroup: undefined,
  selectedAction: '',
  isLoading:false,
  accountusers: [],
  isAddingGroup: false,
  isAddSubmitted: false,
  isEditGroup: false,
  isEditSubmitted: false
};

export const fetchAllGroupsList = createAsyncThunk (
    'groups/fetchAllGroupsList',
    async ({userId,accountValue}) => {
      if(userId !== undefined && accountValue !== undefined){
        let url = "getGroupsByAccountId/"+accountValue+"/"+userId;
        const response = await authService.fetch(url,{ method: 'GET'})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }      
        return data;    
      }
    }
)

export const fetchAllGroupUsers = createAsyncThunk(
    'groups/fetchAllGroupUsers',
    async () => {
        let url = "getusersByGroups/";
        const response = await authService.fetch(url,{ method: 'GET'})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }      
        return data;
    }
);

export const addNewGroupToList =  createAsyncThunk(
    'groups/addNewGroupToList',
    async (groupDetails) => {
      if(groupDetails !== undefined){
        let url = "addgroup/";
        const response = await authService.fetch(url, {method: 'POST', body: JSON.stringify(groupDetails)})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }
        else if (data) {
            return {
                ...groupDetails,
                groupid: data,
                users: []            
            }
        }
        return "The addgroup API didn't return a response";        
      }
    }
);

export const deleteGroupFromList = createAsyncThunk( 
    'groups/deleteGroupFromList',
    async (groupValue) => {
      if(groupValue !== undefined){
        let url = "deletegroup/" + groupValue;
        const response = await authService.fetch(url, {method: 'DELETE'})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }
        return {
            message: data,
            groupid: groupValue
        };        
      }
    }
);

export const getAccountUsers = createAsyncThunk(
    'groups/getAccountUsers',
    async accountValue => {
      if(accountValue !== undefined){
        let fetchString = 'getUsersByAccountIdForDropdown/';
        fetchString += accountValue; //by account id
        const response = await authService.fetch(fetchString,{ method: 'GET'})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }   
        const userNames = data.filter(e => (e.isadmin && e.isexpert) || !e.isadmin)   
        return userNames;        
      }
    }  
);

export const editGroupFromList = createAsyncThunk(
    'groups/editGroupFromList',
    async (groupDetails) => {
      if(groupDetails !== undefined){
        let url = "updateGroup/";
        const response = await authService.fetch(url, {method: 'PUT', body: JSON.stringify(groupDetails)})
        const data = await response.json();
        if (response.status < 200 || response.status >= 300) {
            return response.status
        }
        return data;        
      }
    }
);

const groupsSlice = createSlice({
    name: "groups",
    initialState,
    reducers: {
        updateSelectedGroup(state, action){
          state.selectedGroup = action.payload.id;
          state.selectedAction = action.payload.action;
        },
        updateEditSubmitted(state, action){
          state.isEditSubmitted = action.payload;
        }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchAllGroupsList.pending, (state) => {
        state.isGroupLoading = true;
      }),
      builder.addCase(fetchAllGroupsList.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.isGroupLoading = false;
      }),    
      builder.addCase(fetchAllGroupsList.rejected, (state, action) => {
        state.isGroupLoading = false;
        state.errorMessage = action.payload;
      });
      builder.addCase(fetchAllGroupUsers.pending, (state) => {
        state.isGroupUserLoading = true;
      }),
      builder.addCase(fetchAllGroupUsers.fulfilled, (state, action) => {
        state.groupusers = action.payload;
        state.isGroupUserLoading = false;
      });
      builder.addCase(fetchAllGroupUsers.rejected, (state, action) => {
        state.isGroupUserLoading = false;
        state.errorMessage = action.payload;
      }),
      builder.addCase(addNewGroupToList.pending, (state) => {
        state.isAddingGroup = true;
      }),
      builder.addCase(addNewGroupToList.fulfilled, (state, action) => {
        state.groups = [...state.groups, action.payload];
        state.isAddingGroup = false;
        state.isAddSubmitted = true;
      }),
      builder.addCase(addNewGroupToList.rejected, (state, action) => {
        state.isAddingGroup = false;
        state.errorMessage = action.payload;
      }),
      builder.addCase(deleteGroupFromList.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(deleteGroupFromList.fulfilled, (state, action) => {      
        const updatedGroupList = state.groups.filter((group)=> group.groupid !== action.payload.groupid);
        //console.log(updatedGroupList.length);
        state.groups = updatedGroupList;
        state.successMessage = action.payload.message;
        state.isLoading = false;        
      }),
      builder.addCase(deleteGroupFromList.rejected, (state, action) => {
        state.successMessage = undefined;
        state.errorMessage = action.payload;
        state.isLoading = false;  
      }),
      builder.addCase(editGroupFromList.pending, (state) => {
        state.isEditGroup = true;
      }), 
      builder.addCase(editGroupFromList.fulfilled, (state, action) => { 
       const index = state.groups.findIndex(group=> group.groupid === action.payload.groupid);
       let finalGroupList = [...state.groups];
       finalGroupList[index] = action.payload;       
       state.groups = finalGroupList;        
       state.isEditSubmitted = true;
       state.isEditGroup = false;       
      }),
      builder.addCase(editGroupFromList.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isEditGroup = false;
      }),             
      builder.addCase(getAccountUsers.fulfilled, (state, action) => {
          state.accountusers = action.payload
      })
      builder.addCase(getAccountUsers.rejected, (state, action) => {
          state.errorMessage = action.payload;
      })    
    }
});

export default groupsSlice.reducer;
export const { updateSelectedGroup, updateEditSubmitted } = groupsSlice.actions



