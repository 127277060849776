let backend_domain = window._env_.REACT_APP_API_URL
let api_version = window._env_.REACT_APP_API_VERSION

//customer api's
export const customer_api = {
    addcustomer: {
        method: "POST",
        url: `${backend_domain}${api_version}/add/customer`,
        headers: { 'Content-Type': 'application/json' }
    },
    updatecustomer: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/edit/customer`,
        headers: { 'Content-Type': 'application/json' }
    },
    updatecustomergeneralsetting: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/edit/customer-general-settings`,
        headers: { 'Content-Type': 'application/json' }
    },
    updatecustomerjobinfo: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/edit/customer-job-info`,
        headers: { 'Content-Type': 'application/json' }
    },
    updatecustomersessioninfo: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/edit/customer-session-info`,
        headers: { 'Content-Type': 'application/json' }
    },
    updatecustomerintegration: {
        method: "PATCH",
        url: `${backend_domain}${api_version}/edit/customer-integration`,
        headers: { 'Content-Type': 'application/json' }
    },
    getparentcompanies: {
        method: "GET",
        url: `${backend_domain}${api_version}/get/parent-companies`,
        headers: { 'Content-Type': 'application/json' }
    },
    getallcustomer: {
        method: "GET",
        url: `${backend_domain}${api_version}/get/all-customers`,
        headers: { 'Content-Type': 'application/json' }
    },
    getcustomersetting: {
        method: "GET",
        url: `${backend_domain}${api_version}/get/customer-settings`,
        headers: { 'Content-Type': 'application/json' }
    },
    deletecustomer: {
        method: "DELETE",
        url: `${backend_domain}${api_version}/delete/customer`,
        headers: { 'Content-Type': 'application/json' }
    }
}