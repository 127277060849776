import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../../AuthService.js';
let authService = new AuthService();

const initialState = {
  isLoading: false,
  incidents: [],
  error: null,
};

export const fetchIncidents = createAsyncThunk(
  'getIncidents/fetchIncidents',
  async (_, { rejectWithValue }) => {
    try {
       // Retrieve token from sessionStorage within the thunk
       const authenticationToken = sessionStorage.getItem('id_token');
      
       if (!authenticationToken) {
         throw new Error("Authentication token not found");
       }
      const response = await authService.fetch('jobs/getIncidentsOfAccount', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${authenticationToken}`,
        },
      });
      
      if (!response.ok) {
        if (response.status === 400) {
          throw new Error("No incidents found");
        } else if (response.status === 401) {
          throw new Error("Unauthenticated");
        } else {
          throw new Error(`Server error: ${response.status}`);
        }
      }

      return response.json();
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getIncidentsSlice = createSlice({
  name: 'getIncidents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidents.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchIncidents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.incidents = action.payload;
      })
      .addCase(fetchIncidents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default getIncidentsSlice.reducer;
